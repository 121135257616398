body {
  margin: 0;
  font-family: "MontserratRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center;
  background-size: cover;
  background-image: url("./resources/images/backgrounds/backDeskShad2.png");
  /* background-image: url("./resources/images/backgrounds/backLapShad2.png"); */
  /* height: 100vh; */
  min-height: 100vh;
}
:root {
  --textPrimaryColor: #ffffff;
  --textPrimaryDark: #151515;
  --textSecondaryColor: #ff484d;
  --textTertiaryColor: #4285f4;
  --backgroundContentUltraDark: rgba(0, 0, 0, 0.8);
  --backgroundContentSuperDark: rgba(0, 0, 0, 0.6);
  --backgroundContentDark: rgba(0, 0, 0, 0.4);
  --backgroundContent: rgba(0, 0, 0, 0.2);
  --backgroundContentLight: rgba(0, 0, 0, 0.1);
  --backgroundPrimaryDark: #d60206;
  --backgroundPrimaryColor: #e7282d;
  --backgroundPrimaryLight: #fd2d31;
  --backgroundSecondaryDark: #151515;
  --backgroundSecondaryColor: #292929;
  --backgroundSecondaryLight: #3d3d3d;
  --backgroundTertiaryColor: #ffffff;
  --backgroundWallpaper: url("./resources/images/backgrounds/backMobShad1.png");
  --transitionSpeedSonic: 100ms;
  --transitionSpeedFast: 200ms;
  --transitionSpeedSlow: 400ms;
  --transitionSpeedSloth: 1000ms;
  --cornerChips: 1rem;
  --buttonRaise: url("./resources/icons/ButtonRaise.png");
  --buttonPress: url("./resources/icons/ButtonPress.png");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading {
  background-color: rgb(81, 248, 165);
  padding: 1.5%;
  width: 100vw;
}

.body {
  background: rgb(46, 43, 43) !important;
  /* background-image: var(--backgroundWallpaper); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  z-index: 0;
  overflow: hidden;
}

.login {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* top: 100vh;  */
  /* margin-top:5px; */
  left: 0;
  margin-left: 0;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: none;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  transition-duration: 400ms;
}
.jumperRunner {
  pointer-events: auto;
  position: relative;
  padding: 1rem 2.5rem 2.5rem;
  background: rgb(160, 115, 115);
  border-radius: 2.5rem;
  box-shadow: 0 1vh 1vh #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: none;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
}

.mr-auto {
  color: black;
}

.logintitle {
  padding-left: 60%;
  padding-right: 20%;
  padding-bottom: 20%;
}

.button {
  width: 200px;
}

.navLinkIcon {
  max-width: 3rem;
}

.cardin {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  width: 100%;
  margin: 2%;
}
.cardin:hover {
  transition: 0.3s;
  box-shadow: 0 8px 16px 0 rgba(2, 2, 2, 0.2);
}
.cardcampaign {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  /* // margin: 2% !important ; */
}
.whitetext {
  color: #fff;
}
.whitetext2 {
  color: rgb(236, 236, 236);
}
.cardcampaign:hover {
  transition: 0.3s;
  cursor: grab;

  transform: scale(1.01);
  box-shadow: -1px 12px 13px 5px rgba(0, 0, 0, 0.2);
}

.btn:disabled {
  opacity: 1 !important;
}
.buycard {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 2em;
}
.gamecard {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1%;
}
.withoutborder {
  margin-top: 15px;
  border: white !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt20 {
  margin-top: 20px;
}
.mb50 {
  margin-bottom: 50px !important;
}
.tabs1 {
  width: 60%;
  margin: auto !important;
}
.prizes {
  margin: auto;
  width: 100% !important;
  margin-top: 6px;
}
.buttonactive {
  margin-top: 15px;
  background: rgba(0, 0, 0, 0.3) !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 10px !important;
  color: white !important;
  margin-right: 20px;
  border: white !important;
  border-bottom: 4px solid rgb(116, 4, 4) !important;
}
.btn:focus {
  box-shadow: rgba(0, 0, 0, 0) !important;
}
.buttonnonactive {
  margin-top: 15px;
  background: rgba(0, 0, 0, 0.3) !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 10px !important;
  color: white !important;
  margin-right: 20px;
  border: white !important;
}
.price {
  font-size: 30px;
  font-weight: 700;
  font-family: "Montserrat";
  margin-top: 20px;
  color: #f3f3f3;
}
.table-fixed {
  width: 100%;
}
.table-fixed tbody {
  height: 200px;
  overflow-y: auto;
  width: 100%;
}
#reseToast {
  height: 2rem;
  margin: auto;
  color: var(--textPrimaryColor);
  font-weight: bolder;
  text-shadow: 0 0.1rem 0.1rem black;
  background-color: var(--backgroundContentDark);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: var(--transitionSpeedSloth);
}
.boldheader {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  color: #ecebeb;
}
.boldresult {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  color: #fff;
}
.h1 {
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 40px;
  color: white;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.time-wrapper {
  position: relative;
  font-size: 35px;
  font-family: "Montserrat";
}
.bouncy {
  animation-name: stretch;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}
.bouncy1 {
  animation-name: stretching;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}
.countdown {
  margin-top: 50px;
  margin: auto;
  display: inline-flex;
}
.textgame {
  font-size: 17px;
  font-weight: bold;
}
.cont {
  border-radius: 12px !important;
  background: rgba(0, 0, 0, 0.7) !important;
  min-height: 300px;
  box-shadow: -2px 15px 15px 7px rgba(174, 253, 177, 0.2);
  /* background: rgb(8, 111, 27);
  background: -moz-linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(181, 20, 66, 1) 0%,
    rgba(181, 51, 130, 1) 43%,
    rgba(220, 91, 119, 1) 100%
  );
  background: -webkit-linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(181, 20, 66, 1) 0%,
    rgba(181, 51, 130, 1) 43%,
    rgba(220, 91, 119, 1) 100%
  );
  border-radius: 20px;
  background: linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(181, 20, 66, 1) 0%,
    rgba(181, 51, 130, 1) 43%,
    rgba(220, 91, 119, 1) 100%
  ); */
  margin-bottom: 50px;
}
.BuyButton {
  margin-top: 15px;
  font-weight: bold !important;
  font-size: 20px !important;
  background: linear-gradient(to right, #67b26b, #4ca2cb);
  padding: 10px !important;
  border-radius: 14px !important;
  width: 100%;
  border: rgba(23, 31, 25, 1) 100% !important;
  margin-right: 20px;
}
.BuyButton--cancel {
  background: linear-gradient(to right, #ff0018, #dd6a6a);
}

/* .BuyButton:hover {
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
} */
.Button2 {
  margin-top: 15px;
  font-weight: bold !important;
  font-size: 18px !important;
  background: linear-gradient(to right, #67b26b, #4ca2cb);
  color: #000000;
  padding: 10px !important;
  border-radius: 14px !important;
  width: 100%;
  border: rgba(23, 31, 25, 1) 100% !important;
  margin-right: 20px;
}

.Button2:hover {
  background: linear-gradient(to right, #a8f7ac, #8bd4f7);
  transition: 0.3s;
}
.listHeader {
  font-weight: bold;
}
body::-webkit-scrollbar {
  display: none;
}

.jrRegularFill::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.25rem;
}

.jrRegularFill::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.2rem #292929;
  border-radius: 0.25rem;
}

.jrRegularFill::-webkit-scrollbar-thumb {
  background: #e7282d;
  border-radius: 0.25rem;
  border-width: 0.25rem;
  border-style: outset;
  border-color: #e7282d;
}

.demoLogList {
  height: 90%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  color: "white";
  font-family: "Roboto Mono", sans-serif;
}
.demoLogEntry {
  display: flex;
  height: 2rem;
  padding: 0 0.5rem;
  align-items: center;

  justify-content: space-between;
}

.textwhite {
  color: white;
}

.playButton2 {
  margin-top: 25px;
  font-weight: bold !important;
  font-size: 34px !important;

  background: linear-gradient(to right, #67b26b, #4ca2cb) !important;
  /* background: linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(26, 32, 69, 1) 0%,
    rgba(0, 55, 125, 1) 41%,
    rgba(30, 41, 124, 1) 59%,
    rgba(23, 31, 25, 1) 100%
  ); */
  padding: 10px !important;
  border-radius: 14px !important;
  width: 75%;
  border: linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(26, 32, 69, 1) 0%,
    rgba(0, 55, 125, 1) 41%,
    rgba(30, 41, 124, 1) 59%,
    rgba(23, 31, 25, 1) 100%
  ) !important;
}
.playButton:hover {
  letter-spacing: 1px;
  color: #404040;
  font-weight: 700 !important;
  background: none;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(253, 253, 253, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(255, 255, 255, 0.57);
  transition: all 0.3s ease 0s;
}
.playButton {
  margin-top: 25px;
  font-weight: bold;
  font-size: 25px !important;
  background: linear-gradient(to right, #67b26b, #4ca2cb);
  /* background: linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(26, 32, 69, 1) 0%,
    rgba(0, 55, 125, 1) 41%,
    rgba(30, 41, 124, 1) 59%,
    rgba(23, 31, 25, 1) 100%
  ); */
  padding: 10px;
  width: 75%;
  border: linear-gradient(
    49deg,
    rgba(8, 111, 27, 1) 0%,
    rgba(26, 32, 69, 1) 0%,
    rgba(0, 55, 125, 1) 41%,
    rgba(30, 41, 124, 1) 59%,
    rgba(23, 31, 25, 1) 100%
  ) !important;
}
.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}
.backgroundImage {
  /* background-position: center;
  background-size: cover; */
  min-height: 100vh;
  background-color: #292929;
  /* background-image: url("./resources/images/backgrounds/backDeskShad2.png"); */
  /* height: 90vh; */
}
.gameBackgrund {
  background-position: center;
  background-size: cover;
  background-image: url("./resources/images/backgrounds/backDeskShad2.png");
  /* background-image: url("./resources/images/backgrounds/backLapShad2.png"); */
  /* height: 100vh; */
  min-height: 100vh;
}

@media screen and (min-width: 780px) {
  .cardcampaign {
    /* width: 29% !important;
    margin: 2% !important ; */
  }
}
.container1 {
  margin: auto;
  width: 100vw;
  min-height: 100vh;
  /* height: 100vh; */
  padding: 0;
  z-index: 0;
  overflow: hidden;
}

.listbox {
  width: 100%;
  background: rgb(252, 252, 252);
  border-radius: 1rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding-left: 5%;
  margin-top: 2%;
}

.islhomebg {
  padding: 2%;
  background: #c7c5c5;
}

.loader {
  position: absolute;
  left: 50%;
  margin-left: -5rem;
  top: 50%;
  margin-top: -5rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderWheel {
  position: relative;
  display: inline-block;
  border: 0.5rem solid #f3f3f3;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  animation: grow 1s linear forwards, spin 2s linear 2.5s infinite;
  box-shadow: 0 0 1rem inset #000000;
}

.loaderLogo {
  position: absolute;
  display: inline-block;
  width: 7rem;
  height: 7rem;
  opacity: 0;
  animation: growLogo 4s linear 1s infinite;
}

.loaderText {
  position: absolute;
  display: inline-block;
  color: #ffffff;
  width: 7rem;
  height: 7rem;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderChar {
  position: relative;
  opacity: 0;
  animation: growText 4s linear infinite;
}

#load-L {
  animation-delay: 2.7s;
}

#load-O {
  animation-delay: 2.8s;
}

#load-A {
  animation-delay: 2.9s;
}

#load-D {
  animation-delay: 3s;
}

#load-I {
  animation-delay: 3.1s;
}

#load-N {
  animation-delay: 3.2s;
}

#load-G {
  animation-delay: 3.3s;
}
#p2wButton {
  position: relative;
  width: 100%;
  height: 20vh;
  text-align: center;
  filter: grayscale(0%);
  transition-duration: 1000ms;
}
#buttonDiv {
  position: absolute;
  text-align: center;
  top: 2.2vh;
  left: 50%;
  margin-left: -7.75vh;
  width: 15vh;
  height: 15vh;
  border-radius: 7.5vh;
  border: none;
  background-image: url("./resources/icons/ButtonRaise.png");
  background-position: center;
  background-size: 100%;
  box-shadow: 0px 0px 0.5rem 0.25rem red;
}
.buttonRiff {
  opacity: 1;
  position: absolute;
  top: 2vh;
  left: 50%;
  margin-left: -8vh;
  width: 15vh;
  height: 15vh;
  border-radius: 8vh;
  border: 0.5vh solid transparent;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: buttonPulseColour;
  animation-timing-function: ease-out;
  animation-play-state: running;
  pointer-events: none;
  transition-duration: 1000ms;
}
#Riff01 {
  animation-delay: 0s;
}
#Riff02 {
  animation-delay: 0.5s;
}
#Riff03 {
  opacity: 0;
  animation-delay: 1s;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  (min-width: 481px) and (max-width: 767px),
  (min-width: 320px) and (max-width: 480px) {
  #buttonDiv:active {
    background-image: url("./resources/icons/ButtonPress.png");
    cursor: pointer;
  }
}
@media (min-width: 3201px) and (max-width: 3840px),
  (min-width: 2561px) and (max-width: 3200px),
  (min-width: 1921px) and (max-width: 2560px),
  (min-width: 1601px) and (max-width: 1920px),
  (min-width: 1281px) and (max-width: 1600px),
  (min-width: 1025px) and (max-width: 1280px),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #buttonDiv:hover {
    background-image: url("./resources/icons/ButtonPress.png");
    cursor: pointer;
  }
}

@keyframes buttonPulseColour {
  0% {
    top: 2.5vh;
    left: 50%;
    margin-left: -7.5vh;
    width: 13vh;
    height: 13vh;
    border: 1vh solid #ff1313;
    border-radius: 7.5vh;
  }
  40% {
    /*border-color: #feff13;*/
    border-color: rgba(255, 255, 19, 0.75);
  }
  60% {
    /*border-color: #13ff16;*/
    border-color: rgba(19, 255, 22, 0.5);
  }
  80% {
    /*border-color: #1336ff;*/
    border-color: rgba(19, 54, 255, 0.25);
  }
  100% {
    top: 0;
    margin-left: -10vh;
    width: 20vh;
    height: 20vh;
    border: 0 solid transparent;
    border-radius: 10vh;
  }
}

@keyframes buttonPulse {
  0% {
    top: 40vh;
    left: 25vw;
    margin-left: -10vh;
    width: 18vh;
    height: 18vh;
    border: 1vh solid #ff1313;
    border-radius: 11vh;
  }
  100% {
    top: 35vh;
    left: 25vw;
    margin-left: -15vh;
    width: 30vh;
    height: 30vh;
    border: 0vh solid #ffffff;
    border-radius: 24vh;
  }
}
@keyframes stretching {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes stretch {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.4);
  }
}

@keyframes grow {
  0%,
  40% {
    width: 0;
    height: 0;
    border-width: 0;
    box-shadow: 0 0 1rem inset rgba(0, 0, 0, 0);
  }
  70% {
    width: 8.5rem;
    height: 8.5rem;
    border-width: 0.5rem;
  }
  80% {
    width: 7.5rem;
    height: 7.5rem;
  }
  90%,
  100% {
    box-shadow: 0 0 1rem inset #000000;
    width: 8rem;
    height: 8rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-color: #f3f3f3;
  }
  25% {
    border-top-color: #e7282d;
  }
  50% {
    border-color: #f3f3f3;
  }
  75% {
    border-top-color: #e7282d;
    width: 8rem;
    height: 8rem;
  }
  80% {
    width: 9rem;
    height: 9rem;
  }
  85% {
    width: 8rem;
    height: 8rem;
  }
  90% {
    width: 9rem;
    height: 9rem;
  }
  95% {
    width: 8rem;
    height: 8rem;
  }
  100% {
    transform: rotate(360deg);
    border-color: #f3f3f3;
  }
}

@keyframes growLogo {
  0%,
  10% {
    opacity: 0;
    width: 0;
    height: 0;
  }
  17.5% {
    opacity: 1;
    width: 7.5rem;
    height: 7.5rem;
  }
  20% {
    width: 6.5rem;
    height: 6.5rem;
  }
  22.5%,
  25% {
    opacity: 1;
    width: 7rem;
    height: 7rem;
  }
  35% {
    opacity: 1;
    width: 7rem;
    height: 7rem;
  }
  45%,
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

@keyframes growText {
  0% {
    opacity: 0;
    top: 0;
  }
  5% {
    top: -0.5rem;
  }
  10% {
    top: 0;
  }
  17.5% {
    opacity: 1;
  }
  20% {
  }
  22.5%,
  25% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

@keyframes hideLoader {
  0% {
    padding-bottom: 0;
    top: 0;
  }
  40% {
    padding-bottom: 10rem;
    top: -10rem;
  }
  100% {
    top: 100vh;
  }
}

.loaderPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #292929;
  z-index: 200;
}

.cardus {
  margin-top: 50px;
}
.cardabout {
  margin-top: 10px;
  background-color: #000000;
  opacity: 0.6;
}

.abouttext {
  color: #000000;
}

.horBar {
  margin: 0 1rem;
  width: 0.1rem;
  background-color: rgb(0, 0, 0);
}

.cardbottom {
  margin-bottom: 50px;
}

.containerheight {
  height: 100vh;
}

.profile-wrapper {
  border-radius: 5px !important;
  /* background:  linear-gradient( #67b26b, #050505) !important;  */
  background: rgba(0, 0, 0, 0.3) !important;
  min-height: 400px;
  padding-bottom: 20px;
  margin-top: 100px;
}

.profile-wrapper:hover {
  box-shadow: -2px 12px 13px 5px rgba(174, 253, 177, 0.2);
}

.tablehover:hover {
  background: rgba(0, 0, 0, 0.3) !important;
}

.profile-image {
  position: relative;
  top: -50px;
  left: 45%;
  transition: transform 0.2s;
  width: 100px !important;
}
.profile-image:hover {
  transform: scale(1.1);
}

.emailalign {
  position: relative;
  top: -50px;
  margin-left: 44%;
  margin-right: 5%;
  word-break: break-all;
}
.emailalign:hover {
  font-weight: bold;
}

.VerticalTimeline {
  border-radius: 5px;
}
.loginBackgrund {
  background-position: center;
  background-size: cover;
  background-image: url("./resources/images/backgrounds/clock.jpg");
  opacity: 0.05;
  min-height: 40vh;
  box-shadow: 10px 10px 10px 10px rgb(129, 167, 118) inset;
}

.price {
  transition: transform 0.2s;
}
/* .price:hover{
  transform: scale(0.9);
} */
.modal-backdrop.show {
  opacity: 1 !important;
}

.textwraping {
  width: 28%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*@media only screen and (min-width: 860px) {*/
/* Disabling everywhere as the payment gets declined*/
.payment-link {
  display: none;
}
/*}*/
