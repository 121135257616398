@font-face {
  font-family: "Josefin Sans"; /*a name to be used later*/
  src: url("../resources/fonts/JosefinSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Poppins"; /*a name to be used later*/
  src: url("../resources/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Roboto"; /*a name to be used later*/
  src: url("../resources/fonts/RobotoSlab-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat"; /*a name to be used later*/
  src: url("../resources/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "MontserratRegular"; /*a name to be used later*/
  src: url("../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Raleway"; /*a name to be used later*/
  src: url("../resources/fonts/Raleway-Regular.ttf");
}

/*---------------------------------------------
		>>> About/Contact Page
	---------------------------------------------*/
.aboutContent,
.teamContent {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 1rem;
}

.aboutContent {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.teamContent {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  /* height: 370px; */
}
.contactContentHolder {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.teamContentHolder {
  flex: 2;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.teamLayout {
  width: 100%;
  display: flex;
  margin: 2rem 0;
}
.headerText {
  text-align: left;
  font-size: 22px;
  font-family: Montserrat;
}
.header {
  margin: 1rem 0;
}

#teamCEO {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.teamText {
  text-shadow: 0 0.2rem 0.2rem #000000;
}

.horBar {
  margin: 0 1rem;
  width: 0.1rem;
  background-color: white;
}

#teamMembers {
  flex: 1;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.teamMemRow {
  height: 6rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.teamPic {
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  box-shadow: 0 0.5rem 1rem #000000;
  border: 0 solid #292929;
}

#teamCEO > .teamPic {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  margin-bottom: 1rem;
}

.teamDetails {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.mapouter {
  margin-top: 1rem;
  position: relative;
  text-align: right;
  height: auto;
  flex: 2;
  flex-shrink: 0;
  border-radius: var(--cornerChips);
  background-color: var(--backgroundContent);
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  border-radius: var(--cornerChips);
}

.locationMap {
  height: 100%;
  width: 100%;
  border-radius: var(--cornerChips);
}

.formContent {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 1rem;
  text-align: left;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.contactForm {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactForm > .formButton {
  width: 10rem;
}

.contactFooter {
  display: flex;
  flex-direction: row;
}

.CFLeft,
.CFRight {
  flex: 1;
  flex-shrink: 0;
}

.CFLeft {
  text-align: left;
}

.CFRight {
  text-align: right;
}

.FontSize1x {
  font-size: 1rem;
}

.FontSize0_75x {
  font-size: 0.75rem;
}

.ColorBlack {
  color: black;
}

.copywrite {
  font-size: 0.75rem;
  margin-top: 0.75rem;
  text-align: center;
  margin-bottom: 0.75rem;
}

.aboutContent {
  width: 76rem;
  padding: 0 2rem;
  padding-bottom: 1rem;
  margin: auto;
}

#aboutData {
  text-align: left;
}

.contactContentHolder {
  width: 80rem;
  margin: auto;
  margin-bottom: 2rem;
}

.teamContentHolder {
  margin-right: 1rem;
}

.teamContent,
.formContent {
  margin-top: 1rem;
  padding: 0 2rem;
}

.mapouter,
.gmap_canvas,
.locationMap {
  min-height: 12rem;
  height: 12rem;
}

.formContent .copywriteSegment:last-child {
  margin-top: auto;
}

.aboutContent {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}
.teamContent {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.mapouter {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}
.formContent {
  grid-column: 4 / 4;
  grid-row: 2 / 4;
}
.inputField,
.textAreaField {
  border-radius: 1rem;
  border-style: inset;
  font-family: "Raleway";
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  width: 95%;
}
.formButton {
  color: #ffffff;
  background-color: #fd2d31;
  border-color: #e7282d;
  height: 3rem;
  width: 6rem;
  border-radius: 1.5rem;
  font-family: "Raleway", sans-serif;
  transition-duration: 400ms;
  font-size: 1rem;
}

.formButton:hover {
  background-color: #e7282d;
  border-color: #d60206;
  cursor: pointer;
}

.contactForm {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.campaignImage {
  width: 300px;
  height: 400px;
  object-fit: contain;
  background-color: transparent;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fd2d31 !important;
}
a {
  color: #fd2d31;
}
/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #e63124;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 16px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
.banner {
  position: absolute;
  left: 50%;
  display: block;
  margin: 100px -200px;
  width: 400px;
  height: 60px;
  border: 1px solid #8a1;
  font: normal 30px/60px "Rye";
  text-align: center;
  color: #451;
  background: #9b2;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15) inset, 0 6px 10px rgba(0, 0, 0, 0.15);
}

.banner::before,
.banner::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: -70px;
  top: 24px;
  display: block;
  width: 40px;
  height: 0px;
  border: 30px solid #9b2;
  border-right: 20px solid #791;
  border-bottom-color: #94b81e;
  border-left-color: transparent;
  transform: rotate(-5deg);
}

.banner::after {
  left: auto;
  right: -70px;
  border-left: 20px solid #791;
  border-right: 30px solid transparent;
  transform: rotate(5deg);
}
.btn-primary {
  border-color: transparent !important;
}

.react-tel-input .country-list {
  text-align: left;
}

.max-width-615 {
  max-width: 615px;
}
